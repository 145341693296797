import { type AxiosResponse } from 'axios';

import {
  type DtoGetHubSpotDealResponse,
  type DtoSearchDealRequest,
  type DtoSearchDealResponse,
  type DtoUpdateHubSpotDealWithCustomPriceRequest,
  type DtoUpdateHubSpotDealWithCustomPriceResponse,
} from '@lp-lib/api-service-client/public';

import { BaseAPIService } from './base.api';

export class HubSpotDealAPI extends BaseAPIService {
  search(
    req: DtoSearchDealRequest
  ): Promise<AxiosResponse<DtoSearchDealResponse>> {
    return this.client.post('/hubspot/deals/search', req);
  }

  getDeal(dealId: string): Promise<AxiosResponse<DtoGetHubSpotDealResponse>> {
    return this.client.get(`/hubspot/deals/${dealId}`);
  }

  updateDealCustomPrice(
    dealId: string,
    req: DtoUpdateHubSpotDealWithCustomPriceRequest
  ): Promise<AxiosResponse<DtoUpdateHubSpotDealWithCustomPriceResponse>> {
    return this.client.put(`/hubspot/deals/${dealId}/custom-price`, req);
  }
}
