import { type AxiosResponse } from 'axios';

import {
  type DtoSingleProgressionResponse,
  type DtoTrackProgressionBlockPlayedRequest,
} from '@lp-lib/api-service-client/public';

import { BaseAPIService } from './base.api';

export class ProgressionAPI extends BaseAPIService {
  createMyProgression(
    packId: string
  ): Promise<AxiosResponse<DtoSingleProgressionResponse>> {
    return this.client.post(`/my/progressions/${packId}`);
  }

  resetMyProgression(
    packId: string
  ): Promise<AxiosResponse<DtoSingleProgressionResponse>> {
    return this.client.post(`/my/progressions/${packId}/reset`);
  }

  resetMyMinigameProgression(
    packId: string,
    minigameId: string
  ): Promise<AxiosResponse<DtoSingleProgressionResponse>> {
    return this.client.post(`/my/progressions/${packId}/reset/${minigameId}`);
  }

  trackMyProgressionBlockPlayed(
    packId: string,
    req: DtoTrackProgressionBlockPlayedRequest
  ): Promise<AxiosResponse<DtoSingleProgressionResponse>> {
    return this.client.post(
      `/my/progressions/${packId}/track-block-played`,
      req
    );
  }
}
