import axios, { type AxiosInstance, type AxiosRequestConfig } from 'axios';

import config from '../../config';
import { uuidv4 } from '../../utils/common';
import { uncheckedIndexAccess_UNSAFE } from '../../utils/uncheckedIndexAccess_UNSAFE';

export interface Paging {
  next?: string | null;
}

export interface Paginable {
  paging?: Paging;
}

export interface PaginatorOptions {
  size?: number;
  initPageToken?: string;
  config?: AxiosRequestConfig;
}

export class Paginator<P extends Paginable, T> {
  private id: string;
  private options: PaginatorOptions;
  private allItems: T[];
  private currentItems: T[];
  private pageToken?: string | null;

  constructor(
    private client: AxiosInstance,
    private url: string,
    private key: string,
    options?: PaginatorOptions
  ) {
    this.id = uuidv4();
    this.options = Object.assign({}, options);
    this.allItems = [];
    this.currentItems = [];
    if (this.options.initPageToken) {
      this.pageToken = this.options.initPageToken;
    }
  }

  async next(): Promise<T[]> {
    return await this.nextRaw();
  }

  private async nextRaw() {
    const requestConfig = { ...this.options.config };
    requestConfig.params = { ...requestConfig.params };
    requestConfig.params.size = this.options.size || config.api.pageSize;
    if (this.pageToken) {
      requestConfig.params.pageToken = this.pageToken;
    }
    const resp = await this.client.get<P>(this.url, requestConfig);
    if (resp.data.paging) {
      this.pageToken = resp.data.paging.next;
    }
    const unchecked = uncheckedIndexAccess_UNSAFE(resp.data)[this.key];
    const nextItems: T[] | null = unchecked;
    if (nextItems) {
      this.allItems = this.allItems.concat(nextItems);
      this.currentItems = nextItems;
    } else {
      this.currentItems = [];
    }

    return this.currentItems;
  }

  allFetched(): T[] {
    return this.allItems;
  }

  hasMore(): boolean {
    // undefined => unknown, treated as true
    // string => true
    // null => false
    return this.pageToken !== null;
  }

  reset(): void {
    this.id = uuidv4();
    this.allItems = [];
    this.currentItems = [];
    if (this.options.initPageToken) {
      this.pageToken = this.options.initPageToken;
    } else {
      this.pageToken = undefined;
    }
  }

  getId(): string {
    return this.id;
  }
}

export class DummyPaginator<P extends Paginable, T> extends Paginator<P, T> {
  constructor() {
    super(axios, '', '');
  }
  async next(): Promise<T[]> {
    return [];
  }

  allFetched(): T[] {
    return [];
  }

  hasMore(): boolean {
    return false;
  }
}
